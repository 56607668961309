export const STORE: any = {
  button: {
    href: {
      au: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      de: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      en: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      es: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      fr: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      nl: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      'pt-br': 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      us: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
    },
    title: {
      alt: {
        au: 'SafetyCulture (iAuditor)',
        de: 'SafetyCulture (iAuditor)',
        en: 'SafetyCulture (iAuditor)',
        es: 'SafetyCulture (iAuditor)',
        fr: 'SafetyCulture (iAuditor)',
        nl: 'SafetyCulture (iAuditor)',
        'pt-br': 'SafetyCulture (iAuditor)',
        us: 'SafetyCulture (iAuditor)',
      },
      download: {
        au: 'Get App',
        de: 'App holen',
        en: 'Get App',
        es: 'Bajar aplicación',
        fr: "Obtenir l'application",
        nl: 'Download App',
        'pt-br': 'Obter aplicativo',
        us: 'Get App',
      },
      label: {
        au: '<strong>SafetyCulture (iAuditor)</strong> - Checklists, inspections & audits.',
        de: '<strong>SafetyCulture (iAuditor)</strong> - Checklisten, Inspektionen & Audits.',
        en: '<strong>SafetyCulture (iAuditor)</strong> - Checklists, inspections & audits.',
        es: '<strong>SafetyCulture (iAuditor)</strong> - Listas de verificación, inspecciones y auditorías.',
        fr: '<strong>SafetyCulture (anciennement iAuditor)</strong> - Listes de contrôle, inspections et audits.',
        nl: '<strong>SafetyCulture (iAuditor)</strong> - Checklists, inspecties & audits.',
        'pt-br':
          '<strong>SafetyCulture (iAuditor)</strong> - Checklists, inspeções e auditorias.',
        us: '<strong>SafetyCulture (iAuditor)</strong> - Checklists, inspections & audits.',
      },
    },
  },
  close: {
    au: 'Close',
    de: 'Schließen',
    en: 'Close',
    es: 'Cerrar',
    fr: 'Fermer',
    nl: 'Sluiten',
    'pt-br': 'Fechar',
    us: 'Close',
  },
};
