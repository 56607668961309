import styles from './Store.module.scss';
import navStyles from '@/components/organisms/Header/Header.module.scss';

import Button from '@/components/atoms/Button';
import DOMPurify from 'isomorphic-dompurify';
import Image from 'next/image';
import Times from '@/icons/maggie/Times';
import Typography from '@/components/atoms/Typography';
import typeStore from '@/utilities/types/Store';
import { STORE } from '@/utilities/constants/Store';
import { getCookie, setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';
import { useLocale } from '@/utilities/contexts/Locale';
import useBranchLink from '@/utilities/hooks/useBranchLink';

interface StoreProps {
  store?: typeStore;
}

const Store = (props: StoreProps) => {
  const { store } = props;

  const locale: string = useLocale();

  const { withAmpDev } = useBranchLink();

  const [isStore, setIsStore] = useState<boolean | null>(true);

  const handleStore = () => {
    setCookie('sc-store', 'true', { maxAge: 60 * 60 * 24 * 365 } as any);
    setIsStore(true);
  };

  useEffect(() => {
    setIsStore(getCookie('sc-store') === undefined ? false : true);
  }, []);

  return !isStore ? (
    <div className={styles.root}>
      <div className={navStyles.container}>
        <div className={styles.container2}>
          <div className={styles.buttonsWrapper}>
            <Button
              aria={STORE.close[locale]}
              className={styles.close}
              icon={true}
              onClick={handleStore}
              style="link"
              type="button"
              size="medium"
            >
              <Times height={24} width={24} />
            </Button>
          </div>
          <div className={styles.textWrapper}>
            <Image
              alt={STORE.button.title.alt[locale]}
              height={30}
              src={`/store/safetyculture.svg`}
              width={30}
            />
            <Typography tag="span" xs={1} className={styles.text}>
              <span
                className={styles.label}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(STORE.button.title.label[locale]),
                }}
              />
            </Typography>

            <Button
              className={styles.button}
              external={true}
              size="small"
              style="secondary"
              href={
                store && store.override && store.getApp
                  ? withAmpDev(store.getApp)
                  : withAmpDev(STORE.button.href[locale])
              }
            >
              {STORE.button.title.download[locale]}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Store;
